import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import {
  faAngry,
  faCaretDown,
  faChevronDown,
  faChevronRight,
  faClock,
  faDollarSign,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';

import {
  // faBalanceScale,
  // faBuilding,
  faExternalLink,
  // faFirstAid,
  // faFileContract as faFileContractRegular,
  faHandHoldingUsd,
  faCopyright,
  faUserSlash,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faExclamationCircle,
  faLongArrowRight,
  faTimes,
  faBalanceScale,
  faBooks,
  faBooksMedical,
  faBuilding,
  faQuestionCircle,
  faFirstAid,
  faFile,
  faFileEdit,
  faFileContract as faFileContractLight,
  faHandsUsd,
  faUserFriends,
} from '@fortawesome/pro-light-svg-icons';

// import {
//   faBalanceScale as faBalanceScaleDuo,
//   faBooksMedical,
//   faBuilding as faBuildingDuo,
//   faFirstAid as faFirstAidDuo,
//   faFileContract as faFileContractDuo,
//   faHandsUsd,
//   faUserFriends,
// } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faAngry,
  fab,
  faCaretDown,
  faChevronRight,
  faChevronDown,
  faClock,
  faFile,
  faFileEdit,
  faFileContractLight,
  faDollarSign,
  faPhone,
  // far
  faBalanceScale,
  faBuilding,
  faExternalLink,
  faCopyright,
  faFirstAid,
  // faFileContractRegular,
  faHandHoldingUsd,
  faUserSlash,
  // fal
  faExclamationCircle,
  faFileContractLight,
  faQuestionCircle,
  faLongArrowRight,
  faTimes,
  // // fad
  // faBalanceScaleDuo,
  faBooksMedical,
  faBooks,
  // faBuildingDuo,
  // faFirstAidDuo,
  // faFileContractDuo,
  faHandsUsd,
  faUserFriends,
  faUsers,
);
