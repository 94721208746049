import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister } from './registerServiceWorker';

import './assets/styles/base.scss';
import './utils/fontawesome';

unregister();

ReactDOM.render(<App />, document.getElementById('root'));
