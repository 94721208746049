import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import './Button.scss';

const Button = ({
  buttonType,
  children,
  className,
  componentType,
  fetchingIconColor,
  href,
  isDisabled,
  isFlat,
  isForm,
  isWarning,
  label,
  onClick,
  size,
  type,
  to,
  // jsx styles
  jsxStyles,
  // borderColor,
  // borderRadius,
  // backgroundColor,
  // width,
  // height,
  // fontSize,
  ...restOfProps
}) => {
  const ButtonComponent = (href && 'a') || (to && Link) || 'button';
  let passedProps = {
    className: classnames(
      className, // Use '&#{&}' to override default styles by doubling a className's specificity
      'myBtn',
      `myBtn--${buttonType}`,
      `myBtn--${size}`,
      { 'myBtn--flat': isFlat, isForm, isWarning },
    ),
    disabled: isDisabled,
    href,
    onClick,
    style: { ...jsxStyles },
    to,
    type,
    ...restOfProps,
  };

  // if (componentType === 'Link') {
  //   passedProps.to = to;
  // } else if (componentType === 'a') {
  //   passedProps = {
  //     ...passedProps,
  //     href,
  //     target,
  //     rel,
  //   };
  // }

  return <ButtonComponent {...passedProps}>{children || label}</ButtonComponent>;
};

Button.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'link']),
  children: PropTypes.node,
  className: PropTypes.string,
  fetchingIconColor: PropTypes.oneOf(['black', 'white', 'mint']),
  isDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isWarning: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'wide', 'lg']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** *** Mainly for style overwriting in Storybook tools  *** */
  jsxStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
  }),
};
Button.defaultProps = {
  buttonType: 'primary',
  componentType: 'button',
  label: 'Next',
  fetchingIconColor: 'white',
  onClick: () => {},
  size: 'md',
  type: 'button',
};

export default Button;
