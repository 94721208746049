function useBEM(bemPrefix) {
  const bem = elementName => {
    const apply = e => `${bemPrefix}${e ? '__' + e : ''}`;
    let className = [];
    if (Array.isArray(elementName)) {
      for (let i of elementName) {
        className.push(apply(i));
      }
      return className.join(' ');
    }
    return apply(elementName);
  };
  return bem;
}

export { useBEM };
