import React, { useCallback } from 'react';

import { Button, TooltipAction } from '../../components/common';
import Topbar from '../../components/common/Topbar';
import checkMark from '../../assets/images/icons/checkmark-icon.svg';
import Footer from '../../components/common/Footer';
import Sitemap from '../../components/common/Sitemap';
import { isEmpty } from 'lodash';

import './Plans.scss';
import UseWindowScroll from '../../utils/UseWindowScroll';

// const upto = units => (
//   <div className="Plans__upToWrapper">
//     <div className="Plans__upTo--small">up to</div>
//     <div className="Plans__units">{units}</div>
//   </div>
// );
const check = <img alt="check-mark" src={checkMark} className="Plans__checkMark" />;
const dash = _ => <b className="Plans__dash">-</b>;
// const included = count => (
//   <div className="Plans__upToWrapper">
//     <div className="Plans__units">{count}</div>
//     <div className="Plans__upTo--small">included</div>
//   </div>
// );
const compileRow = row => {
  return (
    <div key={JSON.stringify(row[0])} className="Plans__tieredContainer">
      <div className="Plans__tierFeature">
        <p>
          {row[0]} <TooltipAction text={row[4]} />
        </p>
      </div>
      <div className="Plans__tierInclusion Plans__tierInclusion--bordered">{row[1]}</div>
      <div className="Plans__tierInclusion Plans__tierInclusion--bordered">{row[2]}</div>
      <div className="Plans__tierInclusion">{row[3]}</div>
    </div>
  );
};
const getTierRows = _ => {
  let rows = [];
  for (let row of tierDictionary) {
    rows.push(compileRow(row));
  }
  return rows;
};

const tierDictionary = [
  [
    'Automated Workflows & Templates',
    'Pre-Funding',
    check,
    check,
    'End-to-end workflows for private capital operations with shareable forms, automated templates, deal/data rooms, e-signatures, filing integrations, & help content.',
  ],
  [
    'Entity Workspaces',
    check,
    check,
    check,
    'Collaborative, secure entity workspaces to manage entity documents, compliance, & governance.',
  ],
  [
    'Filings & Compliance',
    check,
    check,
    check,
    'Integrated filing & compliance services for private funds and businesses (i.e., all-50 states, Blue Sky, Beneficial Owner Reporting, K1s, etc.)',
  ],
  [
    'Doc Send & Sign',
    check,
    check,
    check,
    'Generate docs and share securely for review and esignature.',
  ],
  [
    'Deal/Data Rooms',
    check,
    check,
    check,
    'Create secure data rooms to share sensitive documents, links, & reports. Manage access & track engagement.',
  ],
  [
    'Specialized Providers',
    check,
    check,
    check,
    'Connect with our growing Provider Network to access specialized services, workflows, & templates for your unique needs. Provider coverage includes Venture Capital, Private Equity, Real Estate, Hedge Funds, Franchises, and more. Fees may vary.',
  ],
  [
    'Personal Dashboards',
    check,
    check,
    check,
    "Track and manage all of your Paper to-do's, documents, and deals from one integrated dashboard.",
  ],
  [
    'Stakeholder Portals',
    dash(),
    check,
    check,
    'Comprehensive portals for stakeholder discovery/diligence, deals, onboarding, and reporting.',
  ],
  [
    'Portfolio Reporting',
    dash(),
    check,
    check,
    'Tools for collecting and sharing financial and performance data with relevant stakeholders.',
  ],
  [
    'Intake Forms',
    dash(),
    check,
    check,
    'Build and share pages with custom forms & content to streamline intake for your Portals and Workflows.',
  ],
  [
    'Custom Branding',
    dash(),
    check,
    check,
    'Custom branded forms, data rooms, and portals.',
  ],
  [
    'Custom Logic',
    dash(),
    check,
    check,
    'Host custom Workflows & templates or branch a licensed library for use within your Portfolios.',
  ],
  [
    'Knowledge Licensing',
    dash(),
    check,
    check,
    'License from our growing repository of shared template & workflow libraries, which can be branched & customized.',
  ],
  [
    'Plug-in Integrations',
    dash(),
    dash(),
    check,
    'Plug-and-play integrations, including Mercury, Verivend, HubSpot, Box, and Stripe.',
  ],
  [
    'Custom Integrations',
    dash(),
    dash(),
    check,
    'Access our private API and development services for custom integrations.',
  ],
  [
    'Embedded UI',
    dash(),
    dash(),
    check,
    'Embed forms, reports, and other UI within your own application.',
  ],
  [
    'White-label Platform',
    dash(),
    dash(),
    check,
    'Custom URL & branding for your own platform and ecosystem of users.',
  ],
  [
    'AI Toolkit',
    dash(),
    dash(),
    check,
    'AI document analysis & ledger creation (with additional AI tools available in private beta).',
  ],
  [
    'Doc Ledgers',
    'up to 100 Docs',
    'up to 2,000 Docs',
    check,
    'Connected docs create ledgers for streamlined diligence and seamless reporting for both internal & external stakeholders.',
  ],
  [
    'Seats',
    '1 Included',
    'Unlimited',
    'Unlimited',
    'Invite in-house our outsourced team members to collaborate.',
  ],
];

const Plans = () => {
  const { scrollY } = UseWindowScroll();
  let stickyHeader = useCallback(
    node => {
      if (!isEmpty(node) && scrollY > 270) {
        node.classList.add('Plans__tieredColumnsHeaders--stuck');
      } else if (!isEmpty(node) && scrollY < 270) {
        node.classList.remove('Plans__tieredColumnsHeaders--stuck');
      }
    },
    [scrollY],
  );
  return (
    <>
      <Topbar isPlans />
      <div className="Plans">
        <div className="Plans__wrapper">
          <div className="Plans__introductionText">
            <h1 className="Plans__introductionText--header">Platform Plans</h1>
            <h3 className="Plans__introductionText--subheader">
              Unlock scale in your private capital operations.
            </h3>
          </div>
          <div className="Plans__tieredColumns">
            <div ref={stickyHeader} className="Plans__tieredColumnsHeaders">
              <div className="Plans__tier">
                <Button
                  className="Plans__getStarted"
                  href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
                  size="sm"
                >
                  Get Started
                </Button>
              </div>
              <div className="Plans__tier">
                {/* <div className="Plans__tierType">Professional</div>
                <div className="Plans__tierCost">Free</div> */}
                <div className="Plans__tierCost">Professional</div>
              </div>
              <div className="Plans__tier">
                {/* <div className="Plans__tierType">Portfolio</div>
                <a
                  className="Plans__tierCallUs"
                  href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Talk to Sales
                </a> */}
                <div className="Plans__tierCost">Portfolio</div>
              </div>
              <div className="Plans__tier">
                {/* <div className="Plans__tierType">Enterprise</div>
                <a
                  className="Plans__tierCallUs"
                  href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Request Quote
                </a> */}
                <div className="Plans__tierCost">Platform</div>
              </div>
            </div>
            {getTierRows()}
          </div>
          <div className="Plans__relief--md">&nbsp;</div>
          {/* <div className="Plans__wrapper">
            <div className="Plans__introductionText">
              <div className="Plans__introductionText--large">Still have questions?</div>
              <div className="Plans__introductionText--normal">
                We'd be happy to help. Feel free to{' '}
                <Button size="sm" buttonType="link" onClick={activateHubspotChatter}>
                  contact us via chat
                </Button>{' '}
                during business hours or at{' '}
                <a href="mailto:onboarding@savvi.legal">onboarding@savvi.legal</a>.
              </div>
              <div>
                <div className="Plans__relief">&nbsp;</div>
                <Button
                  className="Plans__contactUs"
                  href="https://meetings.hubspot.com/onboarding48/master-onboarding-link"
                  size="sm"
                >
                  Talk to us
                </Button>
              </div>
            </div>
          </div> */}
          {/* <div className="Plans__relief--lg">&nbsp;</div> */}
        </div>
        <Sitemap />
        <Footer />
      </div>
    </>
  );
};

export default Plans;
