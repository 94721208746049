import React from 'react';
import { generateBem } from '../../utils/generateBem';
import './PaperOSBranding.scss';

const bem = generateBem('PaperOSBranding');
const PaperOSRaise = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('savvi')}>PaperOS </b>
      <b className={bem('dotgo')}>Raise</b>
    </h1>
  );
};
const PaperOSGo = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('paperOS')}>PaperOS </b>
      <b className={bem('dotgo')}>Go</b>
    </h1>
  );
};
const PaperOSFund = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('paperOS')}>PaperOS </b>
      <b className={bem('dotgo')}>Funds</b>
    </h1>
  );
};
const PaperOSSyndicate = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('paperOS')}>PaperOS </b>
      <b className={bem('dotgo')}>Syndicate</b>
    </h1>
  );
};
export { PaperOSGo, PaperOSRaise, PaperOSFund, PaperOSSyndicate };
