import React from 'react';
import PropTypes from 'prop-types';

import './HorizontalCard.scss';
import { generateBem } from '../../../utils/generateBem';
import { useLocation } from 'react-router';

const bem = generateBem('HorizontalCard');

const HorizontalCard = ({ icon, title, description, href, invisible, linkText }) => {
  const { search = '' } = useLocation();
  const target = linkText ? '_blank' : '';
  const rel = linkText ? 'noreferrer' : '';
  return (
    <div
      className={bem([
        'wrapper',
        invisible && 'wrapper--invisible',
        linkText && 'wrapper--stationary',
      ])}
    >
      <a href={href + search} target={target} rel={rel}>
        <div className={bem('split')}>
          <div className={bem('icon')}>
            <img src={icon} alt={title} />
          </div>

          <div className={bem('content')}>
            <h3 className={bem('title')}>{title}</h3>
            <p className={bem('description')}>{description}</p>
            {linkText && <h4 className={bem('link')}>{linkText}</h4>}
          </div>
        </div>
      </a>
    </div>
  );
};

HorizontalCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
};

HorizontalCard.defaultProps = {
  icon: '',
  isLoading: false,
  isDisabled: false,
  className: '',
  onClick: () => {},
  type: 'button',
};

export { HorizontalCard };
