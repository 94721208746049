import React from 'react';
import PropTypes from 'prop-types';

import './GrayscaleRibbon.scss';
import { Tooltip } from '..';

const bem = elementName => `GrayscaleRibbon${elementName ? '__' + elementName : ''}`;

const GrayscaleRibbon = ({ tiles, title }) => (
  <section className={bem('wrapper')}>
    <h1 className={bem('tileSectionHeader')}>{title}</h1>
    <div className={bem('tileWrapper')}>
      {tiles.map((tile, index) => (
        <div className={bem('tile')} key={`tile-${index}`}>
          <img
            style={{ width: tile.width || 120 }}
            src={tile.img}
            alt={`logo-${tile.label}`}
          />
          <Tooltip align="center" text={tile.label} />
        </div>
      ))}
    </div>
  </section>
);

GrayscaleRibbon.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

GrayscaleRibbon.defaultProps = {
  title: 'Communities we serve',
};

export default GrayscaleRibbon;
