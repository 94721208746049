import React from 'react';

import Footer from '../../components/common/Footer';

import syndicateSvg from '../../assets/images/icons/syndicate-icon.svg';
import usersIcon from '../../assets/images/icons/users-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import buildingIcon from '../../assets/images/icons/building-icon.svg';
import documentIcon from '../../assets/images/icons/document-icon.svg';

import portalExample from '../../assets/images/examples/paperos-portal.png';

import { Button, TestimonialsList, Sitemap, Topbar } from '../../components/common';
import { Testimonials } from '../../utils/Testimonials';

import './Home.scss';
import { FloatingList } from '../../components/common/FloatingList/FloatingList';
import { generateBem } from '../../utils/generateBem';

const bem = generateBem('Home');

const floatingList = [
  {
    icon: syndicateSvg,
    title: 'AI Enablement',
  },
  {
    icon: documentIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Doc Toolkits',
  },
  {
    icon: buildingIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Front-office Portals',
  },
  {
    icon: scalesIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Filing Automation',
  },
  {
    icon: stackIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Knowledge Libraries',
  },
  {
    icon: usersIcon,
    iconClassName: bem('floatingIcon'),
    title: 'API & No-Code Tools',
  },
];

const Home = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroColumn')}>
          <div className={bem('heroTextWrapper')}>
            <h1 className={bem('heroHeading')}>Transact at Scale</h1>
            <h3 className={bem('heroSubtext')}>
              AI-enabled scale for front & back-end paper ops.
            </h3>
          </div>
          <Button
            className={bem('heroButton')}
            isFlat
            href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={portalExample}
            alt="Savvi Site Example"
          />
          <div className={bem('floatingList')}>
            <FloatingList items={floatingList} zIndex="80" />
          </div>
        </div>
      </div>
    </section>
    <section className="container container--light">
      <div className="row">
        <TestimonialsList listItems={Testimonials} />
      </div>
    </section>
    <Sitemap />
    <Footer />
  </>
);

export default Home;
