import React from 'react';
import { generateBem } from '../../../utils/generateBem';

import './TestimonialsList.scss';

const bem = generateBem('TestimonialsList');

const Testimonial = ({
  className,
  isBackgroundShowing,
  isLogoTall,
  name,
  src,
  text,
  title,
  style,
}) => (
  <div className={`${bem('testimonial')} ${className || ''}`}>
    <div className={bem(['testimonialRow', isLogoTall ? 'testimonialRow--tall' : null])}>
      <div
        className={bem([
          'testimonialLogo',
          isBackgroundShowing ? 'testimonialLogo--background' : null,
        ])}
      >
        <img style={style} src={src} alt={`logo-${title}`} />
      </div>
      <div className={bem('testimonialDivider')} />
      <h4>{` ${name} (${title})`}</h4>
    </div>
    <div className={bem('testimonialContent')}>
      <p>
        <em>{`"${text}"`}</em>
      </p>
    </div>
  </div>
);

export const TestimonialsList = ({ listItems }) => {
  return (
    <section className={bem('wrapper')}>
      <h2 className={bem('header')}>What people are saying</h2>
      <div className={bem('items')}>
        {listItems.map((item, index) => (
          <Testimonial key={`testimonial-${index}`} {...item} />
        ))}
      </div>
    </section>
  );
};
