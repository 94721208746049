import clientbookLogo from '../assets/images/partnerLogos/clientbookLogo.png';
import spacestationLogo from '../assets/images/partnerLogos/spacestationLogo.png';

export const Testimonials = [
  {
    text: "PaperOS has been instrumental in Spacestation Investments' success. We are creating 40+ SPV's per year and Savvi makes the legal process, onboarding investors, and tax process flawless for us so we can focus on investing in great companies instead of managing our SPV's. Their support team is world-class and meets our needs every time. I couldn't recommend PaperOS enough for someone who needs a solid partner to manage SPV's with flexibility, affordability, and agility.",
    name: 'Jaxon Stuart',
    title: 'Fund Manager',
    src: spacestationLogo,
    isBackgroundShowing: true,
    style: {
      width: 180,
      marginRight: 20,
    },
  },
  {
    text: "PaperOS has simplified the legal aspects of running our business and saved us both significant time and money. Whether it's onboarding new employees or having everything ready to go for fundraising due diligence, PaperOS has simplified every aspect of our legal process and become an integral part of our business. Every startup should use PaperOS.",
    name: 'Brandon Wright',
    title: 'CEO',
    src: clientbookLogo,
    style: {
      width: 180,
      marginRight: 20,
    },
  },
];
