import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './Tooltip.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UseWindowSize from '../../../utils/UseWindowSize';

const Tooltip = ({ text, align = 'left', position = 'top' }) => {
  return (
    <span
      className={`savvi-tooltip savvi-tooltip--align-${align} savvi-tooltip--position-${position}`}
    >
      {text}
    </span>
  );
};

const TooltipAction = ({ text, align = 'left', position = 'top', Toggle }) => {
  const { width } = UseWindowSize();
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const handleClose = () => {
      setIsShowing(false);
    };
    if (isShowing) {
      window.addEventListener('click', handleClose);
    }
    return () => window.removeEventListener('click', handleClose);
  }, [isShowing, setIsShowing]);

  return (
    <sup className="savvi-tooltip__wrapper">
      {Toggle && (
        <Toggle
          onClick={e => {
            e.preventDefault();
            if (width < 1024) {
              setIsShowing(!isShowing);
            }
            setIsShowing(!isShowing);
          }}
        />
      )}
      {!Toggle && (
        <FontAwesomeIcon
          className="savvi-tooltip__toggle"
          onClick={e => {
            e.preventDefault();
            if (width < 1024) {
              setIsShowing(!isShowing);
            }
          }}
          icon={['fal', 'question-circle']}
        />
      )}
      <span
        className={classNames(
          `savvi-tooltip savvi-tooltip--align-${align} savvi-tooltip--position-${position}`,
          {
            'savvi-tooltip--visible': isShowing,
          },
        )}
      >
        {text}
      </span>
    </sup>
  );
};

const TooltipPropTypes = {
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
};

Tooltip.propTypes = TooltipPropTypes;
Tooltip.defaultProps = {
  align: 'left',
  position: 'top',
};

TooltipAction.propTypes = {
  ...TooltipPropTypes,
  Toggle: PropTypes.node,
};
TooltipAction.defaultProps = {
  align: 'center',
  position: 'top',
};

export { Tooltip as default, TooltipAction };
