import React from 'react';

import './FloatingList.scss';
import { useBEM } from '../../../utils/UseBEM';

const FloatingList = ({ items }) => {
  const bem = useBEM('FloatingList');

  const arrayLength = items.length;
  let itemCounter = 0;
  const list = items.map(({ icon, iconClassName, title }) => {
    let className;
    if (itemCounter === 0) {
      className = 'item--first';
    } else if (itemCounter && itemCounter < arrayLength - 1) {
      className = 'item--mid';
    } else {
      className = 'item--last';
    }
    ++itemCounter;
    return (
      <li key={`rounded-list-item-${title}`} className={bem(['item', className])}>
        <div className={bem('imgTitle--wrapper')}>
          <div className={bem('imgWrapper')}>
            <img className={iconClassName} src={icon} alt={title} />
          </div>
          <div className={bem('title')}>{title}</div>
        </div>
      </li>
    );
  });

  return (
    <div className={bem('wrapper')}>
      <ul className={bem('list')}>{list}</ul>
    </div>
  );
};

export { FloatingList };
