import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import handshakeIcon from '../../assets/images/icons/handshake-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import documentIcon from '../../assets/images/icons/document-icon.svg';
import searchIcon from '../../assets/images/icons/search-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';

import portalExample from '../../assets/images/examples/paperos-portal.png';
import reportsExample from '../../assets/images/examples/paperos-reports.png';
import projectsExample from '../../assets/images/examples/projects.png';
import docsExample from '../../assets/images/examples/file-cabinet.png';

import { Button, Footer, FloatingList, Sitemap, Topbar } from '../../components/common';
import { PaperOSFund } from '../../components/PaperOSBranding';

import { generateBem } from '../../utils/generateBem';
import './Funds.scss';

const bem = generateBem('Funds');

const solutionsList = [
  {
    icon: handshakeIcon,
    iconClassName: bem('floatingIcon'),
    image: reportsExample,
    title: 'Fundraising & Formation',
    description:
      'One-stop shop for fundraising & formation with support for any structure including LP/GP funds, Syndicates (Series LLCs), rolling subscriptions (LP series), and custom-drafted docs.',
  },
  {
    icon: stackIcon,
    iconClassName: bem('floatingIcon'),
    image: portalExample,
    title: 'Closing & Compliance',
    description:
      'Seamless LP onboarding including data rooms, PPM & subscription automation, investor intake forms, closing links, and capital calls. Integrated compliance services include KYC/AML, Investor Accreditation, Form D (Blue Sky), K1 Tax Filings, etc.',
  },
  {
    icon: documentIcon,
    image: projectsExample,
    iconClassName: bem('floatingIcon'),
    title: 'Reporting & Reminders',
    description:
      'Simple Investor Portals with portfolio summaries, adaptable reports, & action reminders.',
  },
  {
    icon: searchIcon,
    iconClassName: bem('floatingIcon'),
    image: docsExample,
    title: 'Deals & Diligence',
    longTitle: 'Investment Deals & Diligence',
    description:
      'Streamlined investment ops with deal dashboards, AI-enabled diligence, document automation, esignatures, & closing coordination.',
  },
  {
    icon: scalesIcon,
    image: portalExample,
    iconClassName: bem('floatingIcon'),
    title: 'Distributions & Dissolution',
    description:
      'Synchronized end-of-life events including liquidation, distributions, & dissolutions.',
  },
];

const pricingList = [
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Try it Free',
    colHeader: 'Pre-Funding Professionals',
    list: [
      'Fundraising Portals',
      'Data Rooms',
      'Entity Formation*',
      'Operating Agreements',
      'Banking Setup via Mercury',
      'Team Onboarding',
      "NDA's",
    ],
    additionalInfoLabel: 'Additional Professional Features',
    additionalInfoLink: '/plans',
    prePrice: 'Starting at',
    price: '$499',
    postPrice: 'per year',
    footerList: [
      '*Excludes state fees',
      'Registered Agent ($99/yr)',
      'State Filing Agent ($99/yr)',
    ],
  },
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Request a Quote',
    colHeader: 'Fund Portfolios',
    list: [
      'Closing',
      'Compliance*',
      'Reporting',
      'Investments',
      'Multi-Entity Discounts',
    ],
    additionalInfoLabel: 'Additional Portfolio Features',
    additionalInfoLink: '/plans',
    prePrice: 'Pricing varies by Fund Type',
    footerList: ['*Variable fees apply.'],
  },
  {
    buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    buttonText: 'Contact Sales',
    colHeader: 'Fund Services',
    list: [
      'Full-Service Formations',
      'Full-Service Administration',
      'Filing Services',
      'Capital Calls & Wires',
      'End-of-Life Services',
      'Custom Reporting',
      'Template Automation',
      'Portfolio Valuations*',
      'Counsel & Drafting*',
      'Taxes & Financials*',
    ],
    prePrice: 'Flexible Service Plans',
    footerList: ['*via the PaperOS Partner Network'],
  },
];

const Funds = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroColumn')}>
          <div className={bem('heroTextWrapper')}>
            <h1 className={bem('heroHeading')}>
              End-to-End
              <br />
              Fund Suite
            </h1>
            <h3 className={bem('heroSubtext')}>
              One platform for the fund lifecycle with integrated legal, admin & copmliance services.
            </h3>
          </div>
          <Button
            className={bem('heroButton')}
            isFlat
            href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={portalExample}
            alt="Paperos Investors Page Example"
          />
          <div className={bem('floatingList')}>
            <FloatingList items={solutionsList} zIndex="80" />
          </div>
        </div>
      </div>
    </section>
    <div className={bem('section')}>
      {solutionsList.map(
        ({ description, image, title, longTitle, icon }, solutionIndex) => {
          const isEven = (solutionIndex + 1) % 2;
          return (
            !!description && (
              <section
                key={`solution-${title}`}
                className={bem(['sectionRow', isEven ? 'sectionRow--even' : null])}
              >
                <div
                  className={bem([
                    'sectionItem',
                    isEven ? 'sectionItem--rightPadding' : 'sectionItem--leftPadding',
                  ])}
                >
                  <div className={bem('sectionItemRow')}>
                    <img
                      className={bem('sectionItemIcon')}
                      src={icon}
                      alt={`${title} icon`}
                    />
                    <h2>{longTitle || title}</h2>
                  </div>
                  <div className={bem('sectionItemRow')}>
                    <p>{description}</p>
                  </div>
                </div>
                <div className={bem(['sectionItem', 'sectionItem--image'])}>
                  <img
                    className={bem('sectionItemImage')}
                    src={image}
                    alt={`${title} Example UI`}
                  />
                </div>
              </section>
            )
          );
        },
      )}
    </div>
    <section className="container container--light">
      <div className={`${bem(['section', 'section--pricing'])}`}>
        <div className={bem('getStarted')}>
          <p>Get started with</p>
          {PaperOSFund()}
        </div>
        {pricingList.map(
          (
            {
              additionalInfoLabel,
              additionalInfoLink,
              buttonHref,
              buttonText,
              colHeader,
              isSearchAdded,
              list,
              prePrice,
              price,
              postPrice,
              footerList = [],
            },
            pricingCardIndex,
          ) => (
            <div
              key={`pricing-list-item-${colHeader}`}
              className={bem([
                'pricingCard',
                (pricingCardIndex + 1) % 2 ? null : 'pricingCard--even',
              ])}
            >
              <div className={bem('pricingCardList')}>
                <h4>{colHeader}</h4>
                <ul>
                  {list.map(item => (
                    <li key={`price-item-${item}`}>{item}</li>
                  ))}
                  {!!additionalInfoLink && (
                    <Button buttonType="link" size="sm" to={additionalInfoLink}>
                      {additionalInfoLabel}
                      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                    </Button>
                  )}
                </ul>
              </div>
              <div className={bem('pricingCardBottom')}>
                <div className={bem('pricingCardPrePrice')}>{prePrice}</div>
                <div className={bem('pricingCardPrice')}>
                  {!!price && <h2>{price}</h2>}
                  <p className={bem('pricingCardPostPrice')}>{postPrice}</p>
                </div>
                <Button
                  className={bem('pricingCardAction')}
                  href={`${buttonHref}${isSearchAdded ? search : ''}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {buttonText}
                </Button>
                <div className={bem('pricingCardFooter')}>
                  {footerList.map(footer => (
                    <h4 key={`price-footer-${footer}`}>{footer}</h4>
                  ))}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </section>
    <Sitemap />
    <Footer />
  </>
);

export default Funds;
